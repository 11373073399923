import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Header from '../components/Header/Header';
import FinalCta from '../components/shared/FinalCta/FinalCta';

const PageNotFound = () => (
  <Layout>
    <Seo
      title="Edwin - Cybersecurity designed for humans"
      description="Edwin is a behavioral cybersecurity platform that helps organizations reduce security risks, comply with policies, and shape their security culture through small, ongoing, and verifiable security missions."
    />
    <Header demoButtonNavigation="/book-demo" />
    <div className="page-not-found">
      <div className="container">
        <p className="page-not-found__404">404</p>
        <p className="page-not-found__content">
          Sorry, this page doesn&lsquo;t exist.
        </p>
      </div>
    </div>
    <FinalCta />
  </Layout>
);

export default PageNotFound;
